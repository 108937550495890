import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyAbKNBDfdaGJgyXusyhENqzB_nXmSxJneE",
  authDomain: "ecokpala-c1d90.firebaseapp.com",
  projectId: "ecokpala-c1d90",
  storageBucket: "ecokpala-c1d90.appspot.com",
  messagingSenderId: "379760658187",
  appId: "1:379760658187:web:62680f19f617d0dde9840e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
